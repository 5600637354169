import "./src/style/global.scss";

import Provider from './src/state/provider';

export const wrapRootElement = Provider;

// const onClientEntry = () => {
//   if (typeof window !== 'undefined') {
//     const userLang = navigator.language.substr(0, 2)
//     if (window.location.pathname === '/' || (!window.location.pathname.startsWith('/en') && !window.location.pathname.startsWith('/fr'))) {
//       if (userLang === 'fr') {
//         window.location.pathname = `/fr${window.location.pathname}`
//       } else if (!window.location.pathname.startsWith('/en')) {
//         window.location.pathname = `/en${window.location.pathname}`
//       }
//     }
//   }
// };

// export {
//   onClientEntry
// }