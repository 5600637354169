import React, { useState } from 'react';

export const myContext = React.createContext();

const Provider = (props) => {
  const [typography, setTypology] = useState('0');
  const [year, setYear] = useState('0');

  return (
    <myContext.Provider
      value={{
        typography,
        year,
        changeTypology: (event) => setTypology(event),
        changeYear: (event) => setYear(event),
        resetFilters: () => {
          setTypology('0');
          setYear('0');
        },
      }}
    >
      {props.children}
    </myContext.Provider>
  );
};

export default ({ element }) => <Provider>{element}</Provider>;
