// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-fr-js": () => import("./../../../src/pages/contact.fr.js" /* webpackChunkName: "component---src-pages-contact-fr-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mention-en-js": () => import("./../../../src/pages/mention.en.js" /* webpackChunkName: "component---src-pages-mention-en-js" */),
  "component---src-pages-mention-fr-js": () => import("./../../../src/pages/mention.fr.js" /* webpackChunkName: "component---src-pages-mention-fr-js" */),
  "component---src-templates-project-en-js": () => import("./../../../src/templates/project.en.js" /* webpackChunkName: "component---src-templates-project-en-js" */),
  "component---src-templates-project-fr-js": () => import("./../../../src/templates/project.fr.js" /* webpackChunkName: "component---src-templates-project-fr-js" */)
}

